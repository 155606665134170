@tailwind base;
@tailwind components;
@tailwind utilities;
.b{
    border: 2px solid blue;
}
body::-webkit-scrollbar{
    width: 0px;
    height: 0px;
    
}
.result-container::-webkit-scrollbar{
    width: 0px;
    height: 0px;

}
.records-container::-webkit-scrollbar{
    width: 0px;
    height: 0px;
}
.control-box::-webkit-scrollbar{
    width: 0px;
    height: 0px;
}